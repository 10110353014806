body{
  /* height: 676px; */
    max-height: 100%;
    overflow: hidden;
    padding: 15px 0px 0px 0px;
    margin: 0;
    border: 0;
    background: url('../src/bg-globe.jpg');
    background-position-x: center;
    background-repeat: no-repeat;
    background-color: #1c1b17;
    color: #571b1f;
    font-size: 1em;
}
.App {
  margin-left: 50px;
    width: 1100px;
    min-height: 661px;      
}

.AppHeader{
  color: white;
    font-size: 2.0em;
    margin-left: 4%;
    vertical-align: top;
}
.AppNote{
  color: white;
    font-size: 14px;
    margin-left: 16% !important;      
    vertical-align: bottom;
}
.appbarImage{
  background-image: url('../src/bg-globe.jpg');
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.headerLabel {
  color: 'blue';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
