/* 
  .homePageCard {  
      height: 85vh !important;  
      background-image: url('../bg-content-background.png');
      background-repeat: no-repeat;
      background-position: right bottom; 
      overflow: scroll !important;	
    } */
  @media screen and (max-width: 3840px) {
    .homePageCard {    
          background-image: url('../bg-content-background.png');
          height:95vh !important;
          background-repeat: no-repeat;
          background-position: right bottom; 	
      }
    }
   
  @media screen and (max-width: 2560px) {
   .homePageCard {    
       background-image: url('../bg-content-background.png');
       height:90vh !important;
       background-repeat: no-repeat;
       background-position: right bottom; 	
     }
  }

  @media screen and (max-width: 2048px) {
    .homePageCard {    
          background-image: url('../bg-content-background.png');
          height:90vh !important;
          background-repeat: no-repeat;
          background-position: right bottom; 	
      }
    }
@media screen and (max-width: 1920px) {
  .homePageCard {    
      background-image: url('../bg-content-background.png');
      height:82vh !important;
      background-repeat: no-repeat;
      background-position: right bottom;       	
    }
}
@media screen and (max-width: 1280px) {
  .homePageCard {    
      background-image: url('../bg-content-background.png');
      height:80vh !important;
      background-repeat: no-repeat;
      background-position: right bottom; 	
    }
}
@media screen and (max-width: 640px) {
  .homePageCard {    
      background-image: url('../bg-content-background.png');
      height:78vh !important;
      background-repeat: no-repeat;
      background-position: right bottom; 	
    }
}

.raiseDisputeCard {
    height: 80vh;
    background-image: url('../bg-content-background.png');
    background-repeat: no-repeat;
    background-position: right bottom; 
    overflow-y: auto !important;
}
.homePagehr {   
   color: brown;
   width: 1200px;
   height: 2px;
   align-self: baseline;
   background-color: brown;
   margin-left: 280px !important;   
}
.homePageheader {
  color: brown;  
  font-weight: bold !important; 
  font-size: x-large !important;
  padding-left: 280px;
}
.homePageIcon {   
  height: 10px !important;
  width: 10px !important;
  color: #1973b1 !important;
}
.homePageButton{
  font-weight: bold !important;
  color: #1973b1 !important;
  text-decoration: underline !important;
  text-transform: initial !important;
}
.tnCButton{
  font-size: 13px;
  color: #1973b1 !important;
  text-decoration: underline !important;
  text-transform: initial !important;
}
.raiseDisputehr{
   color: brown;  
   height: 2px;
   background-color: brown;
}
.raiseDisputeButton{
  background-color: #1d80c2 !important;
  color: white !important;
}
.raiseDisputeLabel {
  font-weight: bold !important;
  font-size: 13px !important;
}
.raiseDisputeLabelBrac {
    font-weight: bold !important;
    font-size: 11px !important;
}
.optionalLabel {  
  font-size: 13px !important;
}

.furtherTextLabel {  
  font-size: 13px !important;
  font-weight: bold !important;
}

.dialogBox{
  border: "1px solid black" ;    
}
.extpaddingLeft {
  padding-left: 280px !important;	
}
.mobileSelect {
  width: 95px;
  height: 40px;
}
.bankSelect {
  width: 245px;
  /*height: 30px;*/
}
.dialogTxtWidth{
  width: 350px;
  padding-top: 40px;
 
}
.commonWidth{
  width: 150px;
}

.commonWidthArea{
  width: 225px;
}

.currencySelect{
  width: 225px;
  /* height: 10px !important; */
}
.disabledText{
  background: #d3d3d3 !important;  
}
.tranHeader{
  color: brown !important;
  font-weight: bolder !important;
  font-size: '14px' !important;
}
.dialogbox{
  border: brown !important;
  border-radius: 2px;
  overflow: hidden !important;
}
/*.dissuccessLabel{
  width : 500px;
}*/
.disputeSuccessGrid{
  padding-top: 25px;
}
.trackDisputeLabel {
  font-weight: bold !important;
  font-size: 15px !important;
  padding-top: 5px;
 
}
.tableStyle {
  width: 55% !important;
  border:grey;
  border-style: solid;  
  margin-top: 50px;
}
.tableHeader {
  color: #1973b1;
  font-weight: bolder !important;
  font-size: 13px !important;     
  text-align: center;
}
.headercell {
  background-color: rgb(196, 194, 194);
  border: rgb(109, 108, 108);
  border-style: solid; 
  height: 30px;
  width: 30px;
}
.tableCell{
  border: rgb(139, 138, 138);
  border-style: solid; 
  height: 20px;
  width: 30px;
}
.trackDisputeBox{
  background-color: #e0dfdf;
  width: 700px;
  height: 100px;
  border: rgb(187, 186, 186);
  border-style: solid; 
}
.trackDisputeGrid{
  padding-top: 30px;
  padding-left: 35px;
}
.successPopup{
  overflow: hidden;
  background-color:#d3d3d3;
  border-bottom-style: solid;
  border-bottom-color: dimgrey;  
  border-bottom-width: 1px;
}
.sucessPopupButton{
  background-color: #1d80c2 !important;
  color: white !important;
  text-transform: initial !important;
}
.successPopupBox{    
  border-bottom-style: solid;
  border-bottom-color: dimgrey;  
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-color: dimgrey;  
  border-top-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 1px;
}
.successPopupHeader{
  font-size: 18px !important;
  font-weight: bolder !important;
  padding-left: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.waitDialog{
  width:350px;
  align-self: center !important;
}
.homeIcon {   
  height: 23px !important;
  width: 25px !important;  
  color: #1973b1 !important;  
}
